body { background-color: var(--clr-241338); }
body { color: var(--clr-241339); }
[data-pad="0"] { margin-top: 20px; }

[data-pad="1"] { margin-top: 5px; }

h1 {
color: var(--clr-241339);
font-family: 'Montserrat';
font-weight: 700;
font-style: normal;
line-height: 1.1;
letter-spacing: 0;
font-size: 25px;

@media #{$medium-up} {
font-size: 35px;

}
@media #{$large-up} {
font-size: 40px;

}
}
h2 {
color: var(--clr-241339);
font-family: 'Montserrat';
font-weight: 700;
font-style: normal;
line-height: 1.1;
letter-spacing: 0;
font-size: 22px;

@media #{$medium-up} {
font-size: 28px;

}
@media #{$large-up} {
font-size: 35px;

}
}
h3 {
color: var(--clr-241339);
font-family: 'Montserrat';
font-weight: normal;
font-style: normal;
line-height: 1.1;
letter-spacing: 0;
font-size: 20px;

@media #{$medium-up} {
font-size: 24px;

}
@media #{$large-up} {
font-size: 30px;

}
}
h4 {
color: var(--clr-241339);
font-family: 'Montserrat';
font-weight: normal;
font-style: normal;
line-height: 1.1;
letter-spacing: 0;
font-size: 18px;

@media #{$medium-up} {
font-size: 22px;

}
@media #{$large-up} {
font-size: 25px;

}
}
h5 {
color: var(--clr-241339);
font-family: 'Montserrat';
font-weight: 600;
font-style: normal;
line-height: 1.1;
text-transform: uppercase;
letter-spacing: 0;
font-size: 17px;

@media #{$medium-up} {
font-size: 20px;

}
}
h6 {
color: var(--clr-241339);
font-family: 'Montserrat';
font-weight: normal;
font-style: normal;
line-height: 1.1;
letter-spacing: 0;
font-size: 16px;

@media #{$medium-up} {
font-size: 18px;

}
}
.button {
font-family: 'Open Sans';
font-weight: 600;
font-style: normal;
line-height: 1.5;
font-size: 14px;

@media #{$medium-up} {
font-size: 18px;

}
}
.me-Quote .quote-body, .me-HtmlText blockquote {
font-family: 'Arapey';
font-weight: normal;
font-style: italic;
line-height: 1.5;
letter-spacing: 0;
font-size: 22px;

}
.me-Quote .quote-author {
font-family: 'Open Sans';
font-weight: normal;
font-style: normal;
line-height: 1.5;
font-size: 14px;

@media #{$medium-up} {
font-size: 16px;

}
}
ol,ul {
color: var(--clr-241339);
font-family: 'Open Sans';
font-weight: normal;
font-style: normal;
line-height: 1.5;
font-size: 14px;

@media #{$medium-up} {
font-size: 16px;

}
}
ol,ul { margin-bottom: 1em; list-style-position: outside; }
ol { margin-left: 1.25em; list-style-type: decimal; }
ul { margin-left: 1.25em; list-style-type: disc; }
ul, ol { & & { margin-bottom: 0 } }
cite {
font-family: 'Open Sans';
font-weight: normal;
font-style: normal;
line-height: 1.5;
font-size: 14px;

@media #{$medium-up} {
font-size: 16px;

}
}
summary {
font-family: 'Montserrat';
font-weight: normal;
font-style: normal;
line-height: 1.1;
letter-spacing: 0;
font-size: 18px;

@media #{$medium-up} {
font-size: 22px;

}
@media #{$large-up} {
font-size: 25px;

}
}
nav.mainmenu > .menu-item > a {
font-family: 'Open Sans';
font-weight: normal;
font-style: normal;
line-height: 1.5;
letter-spacing: 0;
font-size: 14px;

@media #{$large-up} {
font-size: 16px;

}
}
nav.mainmenu > .menu-item > div > a {
font-family: 'Open Sans';
font-weight: normal;
font-style: normal;
line-height: 1.5;
letter-spacing: 0;
font-size: 14px;

@media #{$large-up} {
font-size: 16px;

}
}
nav.secondarymenu > .menu-item > a {
font-family: 'Open Sans';
font-weight: normal;
font-style: normal;
line-height: 1.5;
letter-spacing: 0;
font-size: 14px;

@media #{$large-up} {
font-size: 16px;

}
}
nav.me-RespMenu.responsive-menu a {
font-family: 'Noto Sans';
font-weight: normal;
font-style: normal;
line-height: 1.5;
letter-spacing: 0;
font-size: 20px;

}
body {
font-family: 'Noto Sans';
font-weight: normal;
font-style: normal;
line-height: 1.5;
font-size: 16px;

}
p { padding-bottom: 1em; }
a {color: var(--clr-241340);}
/* Primary:3 */
.MES3 {
background-color: var(--clr-241341);
color: #ffffff;
 }
/* Primary:4 */
.MES4 {
background-color: var(--clr-241341);
color: #ffffff;
 }
.MES4 {
background-color: var(--clr-241341);
color: #ffffff;
h1.MEC4, h2.MEC4, h3.MEC4, h4.MEC4, h5.MEC4, h6.MEC4 { color: #ffffff;
 }
 }
cite.MEC4{
color: #ffffff;
}
/* Secondary:5 */
.MES5 {
background-color: var(--clr-241340);
color: #ffffff;
 }
/* Secondary:6 */
.MES6 {
background-color: var(--clr-241340);
color: #ffffff;
 }
.MES6 {
background-color: var(--clr-241340);
color: #ffffff;
h1.MEC6, h2.MEC6, h3.MEC6, h4.MEC6, h5.MEC6, h6.MEC6 { color: #ffffff;
 }
 }
cite.MEC6{
color: #ffffff;
}
/* Dark:7 */
.MES7 {
background-color: var(--clr-241342);
color: #ffffff;
 }
/* Dark:8 */
.MES8 {
background-color: var(--clr-241342);
color: var(--clr-241338);
 }
.MES8 {
background-color: var(--clr-241342);
color: var(--clr-241338);
h1.MEC8, h2.MEC8, h3.MEC8, h4.MEC8, h5.MEC8, h6.MEC8 { color: var(--clr-241338);
 }
 }
a.MEC8 { color: var(--clr-241338);
&:hover { color: var(--clr-241340);}
 }
cite.MEC8{
color: var(--clr-241338);
}
/* Light:9 */
.MES9 {
background-color: var(--clr-241338);
 }
/* Light:10 */
.MES10 {
background-color: var(--clr-241338);
 }
.MES10 {
background-color: var(--clr-241338);
 }
/* Shade 1:11 */
.MES11 {
background-color: var(--clr-241343);
 }
/* Shade 1:12 */
.MES12 {
background-color: var(--clr-241343);
 }
.MES12 {
background-color: var(--clr-241343);
 }
/* Menu:13 */
.MES13 {
background-color: #ffffff;
color: var(--clr-241342);
font-size: 16px;
@media #{$medium-up} {
font-size: 17.6px;
};
@media #{$large-up} {
font-size: 19.2px;
};
min-height:50px;
@media #{$medium-up} {
min-height: 80px;};
@media #{$large-up} {
min-height: 100px;};
padding: 0;

border-width: 0 0 3px 0;
border-style: solid;
border-color: transparent transparent var(--clr-241338) transparent;
 }
.MES13 {
background-color: #ffffff;
color: var(--clr-241342);
font-size: 16px;
@media #{$medium-up} {
font-size: 17.6px;
};
@media #{$large-up} {
font-size: 19.2px;
};
min-height:50px;
@media #{$medium-up} {
min-height: 80px;};
@media #{$large-up} {
min-height: 100px;};
padding: 0;

border-width: 0 0 3px 0;
border-style: solid;
border-color: transparent transparent var(--clr-241338) transparent;
 }
a.MEC13 { color: var(--clr-241342);
&:hover { color: var(--clr-241342);}
 }
cite.MEC13{
color: var(--clr-241342);
font-size: 16px;
@media #{$medium-up} {
font-size: 17.6px;
};
@media #{$large-up} {
font-size: 19.2px;
};
}
/* home block panels:14 */
.MES14 {
background-color: #000000;
& > .underlay, & > .inner-overlay { opacity: 0.6 }
& { position: relative;}
& > .me-iwrap { position: relative; z-index: 1; }
& > .underlay { background-image: url('https://ventou.com.au/img/282/47');
background-position: center center;
background-repeat: no-repeat;
top: 0;
background-size: cover;
position: absolute;
bottom: 0;
left: 0;
right: 0;
z-index: 0;
 }
& > * {position: relative;}color: #ffffff;
min-height:300px;
@media #{$medium-up} {
min-height: 400px;};
@media #{$large-up} {
min-height: 500px;};
 }
.MES14 {
background-color: #000000;
& > .underlay, & > .inner-overlay { opacity: 0.6 }
& { position: relative;}
& > .me-iwrap { position: relative; z-index: 1; }
& > .underlay { background-image: url('https://ventou.com.au/img/282/47');
background-position: center center;
background-repeat: no-repeat;
top: 0;
background-size: cover;
position: absolute;
bottom: 0;
left: 0;
right: 0;
z-index: 0;
 }
& > * {position: relative;}color: #ffffff;
min-height:300px;
@media #{$medium-up} {
min-height: 400px;};
@media #{$large-up} {
min-height: 500px;};
h1.MEC14, h2.MEC14, h3.MEC14, h4.MEC14, h5.MEC14, h6.MEC14 { color: #ffffff;
 }
 }
.MEC14 li {color: #ffffff;}
cite.MEC14{
color: #ffffff;
}
/* Pop out text:15 */
.MES15 {
background-color: #ffffff;
color: var(--clr-241342);
font-size: 17.6px;
@media #{$large-up} {
font-size: 19.2px;
};
 }
.MES15 {
background-color: #ffffff;
color: var(--clr-241342);
font-size: 17.6px;
@media #{$large-up} {
font-size: 19.2px;
};
h1.MEC15, h2.MEC15, h3.MEC15, h4.MEC15, h5.MEC15, h6.MEC15 { color: var(--clr-241342);
 }
 }
.MEC15 li {color: var(--clr-241342-flat);}
cite.MEC15{
color: var(--clr-241342);
font-size: 17.6px;
@media #{$large-up} {
font-size: 19.2px;
};
}
/* Hollaw:16 */
.MES16 {
background-color: transparent;
&:hover {background-color: var(--clr-241344);}
color: #ffffff;
&:hover { color: #ffffff;}
font-size: 14px;
@media #{$medium-up} {
font-size: 18px;
};
@media #{$large-up} {
font-size: 19.8px;
};
border-width: 2px;
border-style: solid;
border-color: #ffffff;
&:hover { border-color: #ffffff; }
 }
/* :17 */
.MES17 {
& .slider-arrow {color: #f2f2f2b0;
font-size: 60px;
@media #{$medium-up} {
font-size: 60px;
};
@media #{$large-up} {
font-size: 60px;
};
}& .slider-arrow:hover{color: #ffffffae;
} }
/* Contents:18 */
.MES18 {
background-color: #ffffff;
&:hover, &.hover { background-color: #ffffff;}
color: var(--clr-241342);
font-size: 16px;
 }
.MES18 {
background-color: #ffffff;
&:hover, &.hover { background-color: #ffffff;}
color: var(--clr-241342);
font-size: 16px;
 }
a.MEC18 { color: var(--clr-241340);
&:hover { color: var(--clr-241342);}
 }
cite.MEC18{
color: var(--clr-241342);
font-size: 16px;
}
/* Slider text white 2:19 */
.MES19 {
 }
.MES19 {
h1.MEC19, h2.MEC19, h3.MEC19, h4.MEC19, h5.MEC19, h6.MEC19 { color: #ffffff;
 }
h1.MEC19 { @media #{$large-up} { font-size: 48px; }; }
h2.MEC19 { @media #{$large-up} { font-size: 42px; }; }
h3.MEC19 { @media #{$large-up} { font-size: 36px; }; }
h4.MEC19 { @media #{$large-up} { font-size: 30px; }; }
h5.MEC19 { @media #{$large-up} { font-size: 24px; }; }
h6.MEC19 { @media #{$large-up} { font-size: 21.6px; }; }
 }
/* Footer Dark:20 */
.MES20 {
background-color: var(--clr-241342);
color: var(--clr-241338);
font-size: 12.8px;
 }
.MES20 {
background-color: var(--clr-241342);
color: var(--clr-241338);
font-size: 12.8px;
h1.MEC20, h2.MEC20, h3.MEC20, h4.MEC20, h5.MEC20, h6.MEC20 { color: var(--clr-241338);
 }
 }
a.MEC20 { color: var(--clr-241338);
&:hover { color: var(--clr-241340);}
 }
cite.MEC20{
color: var(--clr-241338);
font-size: 12.8px;
}
/* News grid:21 */
.MES21 {
color: var(--clr-241342);
border-width: 1px;
border-style: solid;
border-color: var(--clr-241338);
 }
.MES21 {
color: var(--clr-241342);
border-width: 1px;
border-style: solid;
border-color: var(--clr-241338);
h1.MEC21, h2.MEC21, h3.MEC21, h4.MEC21, h5.MEC21, h6.MEC21 { color: var(--clr-241341);
 }
 }
cite.MEC21{
color: var(--clr-241342);
}
/* About FAQ:22 */
details.MES22 {
& > summary{background-color: var(--clr-241338);
}
& > summary{padding: 10px 0;}

& > article {border-width: 0 0 1px 0;
border-style: solid;
border-color: transparent transparent #ffffff transparent;
} }
/* Home FAQ:23 */
details.MES23 {
& > summary{background-color: var(--clr-241338);
}
color: var(--clr-241341);
font-size: 14.4px;
& > article { color: var(--clr-241341);
font-size: 14.4px;
 }
& > summary{padding: 10px 0;}

& > article {border-width: 0 0 1px 0;
border-style: solid;
border-color: transparent transparent #ffffff transparent;
} }
/* Product Tile:24 */
.MES24 {
background-color: #ffffff;
&:hover, &.hover { background-color: var(--clr-241343);}
color: var(--clr-241341);
font-size: 16px;
border-width: 1px;
border-style: solid;
border-color: var(--clr-241338);
 }
.MES24 {
background-color: #ffffff;
&:hover, &.hover { background-color: var(--clr-241343);}
color: var(--clr-241341);
font-size: 16px;
border-width: 1px;
border-style: solid;
border-color: var(--clr-241338);
h1.MEC24, h2.MEC24, h3.MEC24, h4.MEC24, h5.MEC24, h6.MEC24 { color: var(--clr-241341);
 }
h1.MEC24 { @media #{$large-up} { font-size: 32px; }; }
h2.MEC24 { @media #{$large-up} { font-size: 28px; }; }
h3.MEC24 { @media #{$large-up} { font-size: 24px; }; }
h4.MEC24 { @media #{$large-up} { font-size: 20px; }; }
h5.MEC24 { @media #{$large-up} { font-size: 16px; }; }
h6.MEC24 { @media #{$large-up} { font-size: 14.4px; }; }
 }
cite.MEC24{
color: var(--clr-241341);
font-size: 16px;
}
/* Dark:26 */
.MES26 {
background-color: var(--clr-241342);
color: #ffffff;
padding: 15px 40px;

 }
/* Check out:27 */
.MES27 {
color: #ffffff;
&:hover { color: #ffffff;}
font-size: 14px;
@media #{$medium-up} {
font-size: 18px;
};
@media #{$large-up} {
font-size: 14.4px;
};
padding: 5px;

border-width: 1px;
border-style: solid;
border-color: #ffffff;
&:hover { border-color: #ffffff; }
 }
/* Top Bar:28 */
.MES28 {
background-color: var(--clr-241341);
color: #ffffff;
font-size: 16px;
@media #{$large-up} {
font-size: 12.8px;
};
 }
.MES28 {
background-color: var(--clr-241341);
color: #ffffff;
font-size: 16px;
@media #{$large-up} {
font-size: 12.8px;
};
h1.MEC28, h2.MEC28, h3.MEC28, h4.MEC28, h5.MEC28, h6.MEC28 { color: #ffffff;
 }
 }
a.MEC28 { color: #ffffff;
&:hover { color: #ffffff;}
 }
cite.MEC28{
color: #ffffff;
font-size: 16px;
@media #{$large-up} {
font-size: 12.8px;
};
}
/* home block panels:29 */
.MES29 {
background-color: #000000;
& > .underlay, & > .inner-overlay { opacity: 0.6 }
& { position: relative;}
& > .me-iwrap { position: relative; z-index: 1; }
& > .underlay { background-image: url('https://ventou.com.au/img/282/47');
background-position: center center;
background-repeat: no-repeat;
top: 0;
background-size: cover;
position: absolute;
bottom: 0;
left: 0;
right: 0;
z-index: 0;
 }
& > * {position: relative;}color: #ffffff;
min-height:300px;
@media #{$medium-up} {
min-height: 400px;};
@media #{$large-up} {
min-height: 500px;};
 }
.MES29 {
background-color: #000000;
& > .underlay, & > .inner-overlay { opacity: 0.6 }
& { position: relative;}
& > .me-iwrap { position: relative; z-index: 1; }
& > .underlay { background-image: url('https://ventou.com.au/img/282/47');
background-position: center center;
background-repeat: no-repeat;
top: 0;
background-size: cover;
position: absolute;
bottom: 0;
left: 0;
right: 0;
z-index: 0;
 }
& > * {position: relative;}color: #ffffff;
min-height:300px;
@media #{$medium-up} {
min-height: 400px;};
@media #{$large-up} {
min-height: 500px;};
h1.MEC29, h2.MEC29, h3.MEC29, h4.MEC29, h5.MEC29, h6.MEC29 { color: #ffffff;
 }
 }
.MEC29 li {color: #ffffff;}
cite.MEC29{
color: #ffffff;
}
/* Category Panel:30 */
.MES30 {
background-color: #ffffff;
&:hover, &.hover { background-color: var(--clr-241343);}
color: var(--clr-241341);
border-radius: 5px;
-webkit-transform: translateZ(0);
overflow: hidden;
border-width: 1px;
border-style: solid;
border-color: var(--clr-241338);
 }
.MES30 {
background-color: #ffffff;
&:hover, &.hover { background-color: var(--clr-241343);}
color: var(--clr-241341);
border-radius: 5px;
-webkit-transform: translateZ(0);
overflow: hidden;
border-width: 1px;
border-style: solid;
border-color: var(--clr-241338);
h1.MEC30, h2.MEC30, h3.MEC30, h4.MEC30, h5.MEC30, h6.MEC30 { color: var(--clr-241341);
 }
 }
cite.MEC30{
color: var(--clr-241341);
}
/* Slider text black 2:31 */
.MES31 {
 }
.MES31 {
h1.MEC31, h2.MEC31, h3.MEC31, h4.MEC31, h5.MEC31, h6.MEC31 { color: var(--clr-241341);
 }
h1.MEC31 { @media #{$large-up} { font-size: 48px; }; }
h2.MEC31 { @media #{$large-up} { font-size: 42px; }; }
h3.MEC31 { @media #{$large-up} { font-size: 36px; }; }
h4.MEC31 { @media #{$large-up} { font-size: 30px; }; }
h5.MEC31 { @media #{$large-up} { font-size: 24px; }; }
h6.MEC31 { @media #{$large-up} { font-size: 21.6px; }; }
 }
/* Phone Block:32 */
.MES32 {
color: #ffffff;
font-size: 25.12px;
@media #{$medium-up} {
font-size: 35.04px;
};
@media #{$large-up} {
font-size: 48px;
};
 }
.MES32 {
color: #ffffff;
font-size: 25.12px;
@media #{$medium-up} {
font-size: 35.04px;
};
@media #{$large-up} {
font-size: 48px;
};
h1.MEC32, h2.MEC32, h3.MEC32, h4.MEC32, h5.MEC32, h6.MEC32 { color: #ffffff;
 }
h1.MEC32 { @media #{$large-up} { font-size: 48px; }; }
h2.MEC32 { @media #{$large-up} { font-size: 42px; }; }
h3.MEC32 { @media #{$large-up} { font-size: 36px; }; }
h4.MEC32 { @media #{$large-up} { font-size: 30px; }; }
h5.MEC32 { @media #{$large-up} { font-size: 24px; }; }
h6.MEC32 { @media #{$large-up} { font-size: 21.6px; }; }
 }
a.MEC32 { color: #ffffff;
&:hover { color: #ffffff;}
 }
cite.MEC32{
color: #ffffff;
font-size: 25.12px;
@media #{$medium-up} {
font-size: 35.04px;
};
@media #{$large-up} {
font-size: 48px;
};
}
/* Back menu:33 */
nav.me-Menu.MES33 {
.menu-item.MEC33{background-color: var(--clr-241340); &:hover {background-color: var(--clr-241341);}
}
& .menu-item.MEC33, & .menu-item.MEC33 > div.MEC33{ & > a.MEC33{color: #ffffff;
font-size: 16px;
text-transform: uppercase;
}
 &:hover > a.MEC33{color: #ffffff;
}
 }
&.horizontal > .menu-item.MEC33 { border:0;
border-color: var(--clr-241338);
border-style: solid;

 border-right-width: 1px;}
&.vertical > .menu-item.MEC33 { border:0;
border-color: var(--clr-241338);
border-style: solid;

border-bottom-width: 1px; }
&.horizontal > .menu-item.MEC33 .menu-item { border:0;
border-bottom-width: 1px;
border-color: 6;
border-style: dotted;
 }
&.horizontal > .menu-item.MEC33 .sub-menu { border:1px;
 }
& > .menu-item > a{padding: 10px 30px;}

& .sub-menu .menu-item a{padding: 5px 10px;}



.menu-item:hover {
& > .sub-menu, & > .pointer-wrap > .sub-menu {

}
}
 }
/* Custom login Panel:34 */
.MES34 {
background-color: var(--clr-241338);
&:hover, &.hover { background-color: var(--clr-241343);}
color: var(--clr-241341);
border-radius: 5px;
-webkit-transform: translateZ(0);
overflow: hidden;
padding: 15px;

@media #{$medium-up} {
padding: 15px 20px;

}
@media #{$large-up} {
padding: 15px 30px;

}
border-width: 1px;
border-style: solid;
border-color: var(--clr-241338);
 }
.MES34 {
background-color: var(--clr-241338);
&:hover, &.hover { background-color: var(--clr-241343);}
color: var(--clr-241341);
border-radius: 5px;
-webkit-transform: translateZ(0);
overflow: hidden;
padding: 15px;

@media #{$medium-up} {
padding: 15px 20px;

}
@media #{$large-up} {
padding: 15px 30px;

}
border-width: 1px;
border-style: solid;
border-color: var(--clr-241338);
h1.MEC34, h2.MEC34, h3.MEC34, h4.MEC34, h5.MEC34, h6.MEC34 { color: var(--clr-241341);
 }
 }
cite.MEC34{
color: var(--clr-241341);
}
/* Separator:35 */
.MES35 {
& > hr {border-width: 1px 0 0 0;}
& > hr {border-color: var(--clr-241342);}
& > hr {&:hover { border-color: var(--clr-241342);} }
& > hr {border-top-style: dotted;}
 }
/* Header Separator:36 */
.MES36 {
& > hr {border-width: 1px;}
& > hr {border-color: var(--clr-241342);}
& > hr {&:hover { border-color: var(--clr-241342);} }
& > hr {border-top-style: dotted;}
& > hr {border-right-style: solid;}
& > hr {border-bottom-style: solid;}
& > hr {border-left-style: solid;}
 }
/* Top Designs Popup:37 */
.MES37 {
background-color: #000000;
& > .underlay, & > .inner-overlay { opacity: 0.3 }
& { position: relative;}
& > .me-iwrap { position: relative; z-index: 1; }
& > .underlay { background-image: url('https://ventou.com.au/img/16078/9507');
background-position: center center;
background-repeat: no-repeat;
top: 0;
background-size: cover;
position: absolute;
bottom: 0;
left: 0;
right: 0;
z-index: 0;
 }
& > * {position: relative;}color: #ffffff;
min-height:250px;
@media #{$medium-up} {
min-height: 250px;};
@media #{$large-up} {
min-height: 250px;};
 }
.MES37 {
background-color: #000000;
& > .underlay, & > .inner-overlay { opacity: 0.3 }
& { position: relative;}
& > .me-iwrap { position: relative; z-index: 1; }
& > .underlay { background-image: url('https://ventou.com.au/img/16078/9507');
background-position: center center;
background-repeat: no-repeat;
top: 0;
background-size: cover;
position: absolute;
bottom: 0;
left: 0;
right: 0;
z-index: 0;
 }
& > * {position: relative;}color: #ffffff;
min-height:250px;
@media #{$medium-up} {
min-height: 250px;};
@media #{$large-up} {
min-height: 250px;};
h1.MEC37, h2.MEC37, h3.MEC37, h4.MEC37, h5.MEC37, h6.MEC37 { color: #ffffff;
 }
 }
.MEC37 li {color: #ffffff;}
cite.MEC37{
color: #ffffff;
}
/* Top Strip Panel:39 */
.MES39 {
background-color: var(--clr-241340);
color: #ffffff;
 }
.MES39 {
background-color: var(--clr-241340);
color: #ffffff;
h1.MEC39, h2.MEC39, h3.MEC39, h4.MEC39, h5.MEC39, h6.MEC39 { color: #ffffff;
 }
h1.MEC39 { font-size: 17.5px; }
h1.MEC39 { @media #{$medium-up} { font-size: 35px; }; }
h2.MEC39 { font-size: 15.4px; }
h2.MEC39 { @media #{$medium-up} { font-size: 28px; }; }
h3.MEC39 { font-size: 14px; }
h3.MEC39 { @media #{$medium-up} { font-size: 24px; }; }
h4.MEC39 { font-size: 12.6px; }
h4.MEC39 { @media #{$medium-up} { font-size: 22px; }; }
h5.MEC39 { font-size: 11.9px; }
h5.MEC39 { @media #{$medium-up} { font-size: 20px; }; }
h6.MEC39 { font-size: 11.2px; }
h6.MEC39 { @media #{$medium-up} { font-size: 18px; }; }
 }
cite.MEC39{
color: #ffffff;
}
/* Responsive Menu:40 */
nav.responsive-menu {
.menu-item.MEC40{background-color: var(--clr-241340);}
& .menu-item.MEC40, & .menu-item.MEC40 > div.menu-item-wrap{ & > a.MEC40, & > i{color: #ffffff;
text-align: left;
}
  }
& .menu-item.MEC40 { border:0;
border-color: #ffffff;border-style: dotted;

 border-bottom-width: 1px; }
& .menu-item > .menu-item-wrap {padding: 10px 15px;}

& .sub-menu{.menu-item.MEC40{background-color: 4;}
& .menu-item.MEC40, & .menu-item.MEC40 > div.menu-item-wrap{ & > a.MEC40, & > i{color: 20;
text-align: left;
}
  }
 .sub-menu {.menu-item.MEC40{background-color: 6;}
& .menu-item.MEC40, & .menu-item.MEC40 > div.menu-item-wrap{ & > a.MEC40, & > i{color: 4;
text-align: left;
}
  }
}}

 }
/* Main Menu:41 */
nav.me-Menu.MES41 {
& .menu-item.MEC41, & .menu-item.MEC41 > div.MEC41{ & > a.MEC41{color: var(--clr-241341);
}
  }
&.horizontal > .menu-item.MEC41 { border:0;

 border-right-width: 1px;}
&.vertical > .menu-item.MEC41 { border:0;

border-bottom-width: 1px; }
&.horizontal > .menu-item.MEC41 .menu-item { border:0;
border-bottom-width: 1px;
border-color: 20;
border-style: dotted;
 }
&.horizontal > .menu-item.MEC41 .sub-menu { border:1px;
 }
& > .menu-item > a{padding: 0 15px;}

@media #{$large-up} {
& > .menu-item > a{padding: 0 25px;}

}
& .sub-menu .menu-item a{padding: 10px 30px;}



&.horizontal .menu-item.MEC41:hover {& > .pointer-wrap > .pointer{border-bottom: 10px solid var(--clr-241340-flat);}}
&.vertical .menu-item.MEC41:hover {& > .pointer-wrap > .pointer{border-right: 10px solid var(--clr-241340-flat);
}}

.menu-item:hover {
& > .sub-menu, & > .pointer-wrap > .sub-menu {
.menu-item.MEC41{background-color: var(--clr-241340); &:hover {background-color: 4;}
}
& .menu-item.MEC41, & .menu-item.MEC41 > div.MEC41{ & > a.MEC41{color: #ffffff;
}
 &:hover > a.MEC41{color: 20;
}
 }

}
}
 }
/* Open Pay :42 */
.MES42 {
background-color: var(--clr-241346);
color: var(--clr-241341);
 }
/* :43 */
.MES43 {
padding: 1px;

 }
/* :44 */
.MES44 {
 }
/* :45 */
.MES45 {
 }
/* :46 */
.MES46 {
 }
/* :47 */
.MES47 {
 }
/* :48 */
.MES48 {
 }
/* Cart panel top banner:49 */
.MES49 {
background-color: #ffffff50;
 }
.MES49 {
background-color: #ffffff50;
 }
/* Button White Border:50 */
.MES50 {
background-color: transparent;
&:hover {background-color: var(--clr-241344);}
color: #ffffff;
font-size: 14px;
@media #{$medium-up} {
font-size: 18px;
};
border-width: 5px;
border-style: solid;
border-color: #ffffff;
 }
/* Page Accordion:51 */
details.MES51 {
 }
/* Button Black Border:53 */
.MES53 {
background-color: transparent;
&:hover {background-color: var(--clr-241344);}
color: #000000;
font-size: 14px;
@media #{$medium-up} {
font-size: 18px;
};
padding: 5px 15px;

border-width: 4px;
border-style: solid;
border-color: #000000;
 }
/* Button White Border small pad:54 */
.MES54 {
background-color: transparent;
&:hover {background-color: var(--clr-241344);}
color: #ffffff;
font-size: 14px;
@media #{$medium-up} {
font-size: 18px;
};
padding: 5px 15px;

border-width: 4px;
border-style: solid;
border-color: #ffffff;
 }
/* Transparent White:55 */
.MES55 {
background-color: #ffffff7e;
border-radius: 10px;
-webkit-transform: translateZ(0);
overflow: hidden;
border-width: 1px;
border-style: solid;
border-color: #ffffff;
&:hover { border-color: #ffffff; }
border-top-style: solid;
border-right-style: solid;
border-bottom-style: solid;
border-left-style: solid;
 }
.MES55 {
background-color: #ffffff7e;
border-radius: 10px;
-webkit-transform: translateZ(0);
overflow: hidden;
border-width: 1px;
border-style: solid;
border-color: #ffffff;
&:hover { border-color: #ffffff; }
border-top-style: solid;
border-right-style: solid;
border-bottom-style: solid;
border-left-style: solid;
 }
/* Transparent Grey:56 */
.MES56 {
background-color: var(--clr-241338);
border-radius: 10px;
-webkit-transform: translateZ(0);
overflow: hidden;
border-width: 1px;
border-style: solid;
border-color: #f2f2f2;
&:hover { border-color: #dcdcdc; }
border-top-style: solid;
border-right-style: solid;
border-bottom-style: solid;
border-left-style: solid;
 }
.MES56 {
background-color: var(--clr-241338);
border-radius: 10px;
-webkit-transform: translateZ(0);
overflow: hidden;
border-width: 1px;
border-style: solid;
border-color: #f2f2f2;
&:hover { border-color: #dcdcdc; }
border-top-style: solid;
border-right-style: solid;
border-bottom-style: solid;
border-left-style: solid;
 }
/* Dark Button:57 */
.MES57 {
background-color: var(--clr-241341);
&:hover {background-color: var(--clr-241344);}
color: #ffffff;
font-size: 11.2px;
@media #{$medium-up} {
font-size: 14.4px;
};
border-radius: 100px;
padding: 5px 10px;

 }
/* Retail Shop Banner:58 */
.MES58 {
background-color: #000000;
& > .underlay, & > .inner-overlay { opacity: 0.6 }
& {overflow: hidden; }
& { position: relative;}
& > .me-iwrap { position: relative; z-index: 1; }
& > .underlay { background-image: url('https://ventou.com.au/img/282/47');
background-position: center center;
background-repeat: no-repeat;
top: -5%;
height: 200%;
background-size: cover;
min-height: 0.1px;
position: absolute;
bottom: 0;
left: 0;
right: 0;
z-index: 0;
 }
& > * {position: relative;}color: #ffffff;
min-height:300px;
@media #{$medium-up} {
min-height: 400px;};
@media #{$large-up} {
min-height: 500px;};
 }
.MES58 {
background-color: #000000;
& > .underlay, & > .inner-overlay { opacity: 0.6 }
& {overflow: hidden; }
& { position: relative;}
& > .me-iwrap { position: relative; z-index: 1; }
& > .underlay { background-image: url('https://ventou.com.au/img/282/47');
background-position: center center;
background-repeat: no-repeat;
top: -5%;
height: 200%;
background-size: cover;
min-height: 0.1px;
position: absolute;
bottom: 0;
left: 0;
right: 0;
z-index: 0;
 }
& > * {position: relative;}color: #ffffff;
min-height:300px;
@media #{$medium-up} {
min-height: 400px;};
@media #{$large-up} {
min-height: 500px;};
h1.MEC58, h2.MEC58, h3.MEC58, h4.MEC58, h5.MEC58, h6.MEC58 { color: #ffffff;
 }
 }
.MEC58 li {color: #ffffff;}
cite.MEC58{
color: #ffffff;
}
/* :59 */
.MES59 {
background-color: #2196f3;
 }
.MES59 {
background-color: #2196f3;
 }
/* orange:60 */
.MES60 {
background-color: #ff5722;
 }
.MES60 {
background-color: #ff5722;
 }
/* light blue:61 */
.MES61 {
background-color: #64b5f6;
 }
.MES61 {
background-color: #64b5f6;
 }
/* light orange:62 */
.MES62 {
background-color: #ff8a65;
 }
.MES62 {
background-color: #ff8a65;
 }
/* light light blue:63 */
.MES63 {
background-color: #4fc3f7;
 }
/* light light blue:64 */
.MES64 {
background-color: #4fc3f7;
 }
.MES64 {
background-color: #4fc3f7;
 }
/* light light blue - white text:65 */
.MES65 {
background-color: #4fc3f7;
color: #ffffff;
 }
/* heavy dark blue:66 */
.MES66 {
background-color: #1565c0;
 }
.MES66 {
background-color: #1565c0;
 }
/* heavy dark orange:67 */
.MES67 {
background-color: #ef6c00;
 }
.MES67 {
background-color: #ef6c00;
 }
/* heavy dark teal:68 */
.MES68 {
background-color: #00695c;
 }
.MES68 {
background-color: #00695c;
 }
/* heavy dark indigo:69 */
.MES69 {
background-color: #283593;
 }
.MES69 {
background-color: #283593;
 }
